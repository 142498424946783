#team-header {
    background-image: url(https://cdn-images.welcometothejungle.com/6gcRKhcRhCpBX0AXIt53BgPTOv4JEOjN8AOUQfnqyXw/rs:auto:2000::/q:85/czM6Ly93dHRqLXByb2R1Y3Rpb24vdXBsb2Fkcy93ZWJzaXRlX29yZ2FuaXphdGlvbi9jb3Zlcl9pbWFnZS93dHRqX2ZyL2VuLWJhNzA5NzJhLWQ1MjEtNDg3NC04MDM2LWUyZTc0ZjZiNzEwYy5qcGc);
    background-size: cover;
    background-position: 50% 50%;
    height: 400px;
    display: flex;
    align-items:center;
    justify-content: center;
    h1 {
        display: flex;
        align-items:center;
        justify-content: center;
        margin: auto;
        width:100%;
        height:calc(100% + 48px * 2);
        position: relative;
        top:-48px;
        text-align:center;
        backdrop-filter: brightness(0.8);
        @media only screen and (max-width:1023px) {
            height: calc(100% + 88px + 40px);
            top: -88px;
        }
    }
}

#team-profile-grid {
    margin: 64px auto;
    display: grid;
    grid: auto-flow / repeat(3, 1fr);
    align-items: start;
    @media only screen and (max-width: 767px) {
        grid: auto-flow / repeat(2, 1fr);
    }
    @media only screen and (max-width: 414px) {
        grid: auto-flow / repeat(1, 1fr);
    }
}
.profile-item {
    display:flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    margin-bottom: 64px;
    p {
        margin: auto;
        text-align:center;
    }
    .profile-picture {
        width:124px;
        height: 124px;
        object-fit: cover;
        object-position: center;
        border-radius: 100% !important;
        display:block;
        margin:auto;
        margin-bottom: 16px !important;
    }
    .profile-name {
        font-weight: 600;
    }
}

#job-offers-div {
    h2, p {
        text-align:center;
    }
}

.welcomekit-jobs-list-item {
    margin: 0;
    padding: 0;
}
.welcomekit-jobs-list-item-link {
    position: relative;
    display: block;
    margin: 0;
    padding: 30px 5px;
    border-bottom: 1px solid var(--incarnat);
    text-decoration: none;
    transition: all 0.2s;
    &:after {
        position: absolute;
    top: 50%;
    right: 30px;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    content: ' ';
    border-top: 1px solid var(--incarnat);
    border-right: 1px solid var(--incarnat);
    transform: rotate(45deg);
    transition: all 0.2s;
    }
    &:hover {
        background: white;
        border-bottom-color: var(--incarnat);
        &::after {
            right: 20px;
            border-top-color: var(--incarnat);
            border-right-color: var(--incarnat);
        }
    }
}
.welcomekit-job-name {
    margin: 0 0 10px 0;
    padding: 0;
    font-weight: 500;
    font-size: 19px;
    line-height: 20px;
    color: var(--encre);
}
.welcomekit-job-infos {
    margin: 0;
    padding: 0;
    & > li {
        position: relative;
        display: inline-block;
        margin: 0 34px 0 0;
        color: black;
        &::before {
            position: absolute;
            top: 6px;
            left: -20px;
            content: ' ';
            display: block;
            height: 6px;
            width: 6px;
            background: #ddd;
            border-radius: 50%;
        }
        &:first-child:before {
            display: none;
        }
        .welcomekit-job-description {
            display: block;
            margin: 10px 0 0 0;
            padding: 10px 20px;
            border-left: 2px solid #eee;
            p, ul {
                margin: 0;
                padding: 0 0 10px 0;
                color: #bbb;
            }
            ul li {
                border-left: 1px solid #eee;
                padding-left: 10px;
                margin-bottom: 10px;
            }
            pre {
                border-left: 1px solid #eee;
                padding-left: 10px;
                overflow: auto;
            }
            &::before {
                display: none;
            }
        }
    }
}
.welcomekit-office-city, .welcomekit-department-name {
    display: block;
    padding: 15px;
    background: #fff;
    border-top: 1px solid var(--incarnat);
    border-bottom: 1px solid var(--incarnat);
    font-weight: 500;
    font-size: 17px;
    line-height: 18px;
    text-transform: uppercase;
}
.welcomekit-warning {
    display: block;
    padding: 50px 0;
    text-align: center;
}
#welcomekit-embed {
    margin-bottom:32px;
}