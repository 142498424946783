:root {
    --page-primary: var(--incarnat);
    --page-secondary: var(--incarnat);
}
.product-carousel {
    max-width: 1024px;
    margin:auto;
    display:flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 64px;
    @media only screen and (max-width: 1024px) {
        max-width: calc(100vw - 32px);
    }
    .all-carousel-cards {
        margin:auto;
        display:flex;
    }
}
.carousel-card.main-card {
    filter: drop-shadow(2px 2px 6px rgba(0,0,0,0.24));
}

.indiv-pricing-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    border-color: #F5DED4;
    & > * {
        border: 1px solid #F5DED4;
    }
    
    @mixin essentials-bg {
        background-color: #F9F3F0;
    }

    .offer-header-essentials {
        background-color: #F5D5CE;
        padding: 10px;
        text-align: center;
        p { margin: 0; }
        font-family: var(--CircularA);
        font-weight: 500;
        border-radius: 10px 10px 0 0;
    }

    .offer-name-and-price {
        grid-row-start: 2;
        padding-top: 24px;
        padding-left: 24px;
        font-weight: 300;
        &--essentials {
            @include essentials-bg();
        }
        &--premium {
            border-radius: 10px 10px 0 0;
        }

        .pricing-indiv-value {
            display: flex;
            flex-direction: row;
            align-items: baseline;
            p {
                font-size: 28px;         
                &.term {
                    margin-left: 6px;
                    font-size: 14px;
                }       
            }
        }
    }
    .offer-benefits {
        grid-row-start: 3;
        ul {
            padding-right: 24px;
            padding-left: 24px;
        }
        ul li {
            font-size: 16px;
            list-style: none;
            border-bottom: 1px solid var(--guimauve);

        }
        &--essentials {
            @include essentials-bg();
        }
        border-width: 0 1px 0 1px;
    }
    .offer-cta {
        display: flex;
        width: calc(100% - 34px);
        padding: 16px;
        justify-content: center;
        grid-row-start: 4;
        &--essentials {
            @include essentials-bg();
        }
        border-width: 0 1px 1px 1px;
        border-radius: 0 0 10px 10px;
        button {
            width: 100%;
            margin: 0;
        }
    }
} 

.pricing-card {
    max-width: 280px;
    padding: 16px !important;
    margin:unset;
    margin-left:8px;
    margin-right:8px;
    cursor: pointer;
    *:first-child, *:nth-child(2) {
        justify-self: flex-start;
    }
    *:last-child {
        justify-self: flex-end;
    }
    .title-like-img {
        height: 48px;
        max-width: 88%;
        margin-top: 8px;
        margin-bottom: 8px;
    }
    .price-period {
        align-self:center !important;
    }
    &:hover {
        box-shadow: 0 0 4px var(--page-primary);
    }
    &.pricing-card-selected {
        border: 2px solid var(--page-primary);
    }
    button.primary:last-of-type {
        color: var(--page-primary);
        background-color: var(--perle);
    }
    &.pricing-card-selected button.primary:last-of-type {
        color: var(--perle);
        background-color: var(--page-primary);
    }
    button {
        width: 100%;
    }
}
#pricing-card-nz-edu, #pricing-card-nz-schools {
    --page-primary: var(--navet);
}
#pricing-card-nz-essentials {
    --page-primary: var(--ardoise);
}
.opacity-transition {
    opacity: 0 !important;
    transition: opacity 0.32s ease-in-out !important;
}
#typeform-embed-form {
    display:none;
}
select {
    background-color:transparent;
}
#pricing-card-nz-pro {
    position:relative;
}