@import "./color-palette.scss";
@import "./design-system.scss";
@import "./home.scss";
@import "./pricing.scss";
@import "./maestria.scss";
@import "./education.scss";
@import "./team.scss";
@import "./blog-article.scss";

// We need a rule if we want the imports to be processed
.dummy {
}