/*Specific CSS for this page*/
:root {
    --page-primary: var(--incarnat); /*Change the value of this variable to a different color in the design system to change color of the elements*/
    --page-secondary: var(--prune); /*Change the value of this variable to a different color in the design system to change color of the elements*/
}

#subscription {
    h2 {
        font-size: 48px;
        text-align:center;
        @media only screen and (max-width:1023px) {
            font-size:36px;
        }
    }
}

.home-hero {
    display:flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    align-items: center;
    max-width: calc(100vw - 64px);
    min-height: 56vh;
    margin: auto;
    @media only screen and (max-width:1023px) {
        flex-flow: column nowrap;
        justify-content: center;
    }
    h1 {
        font-size: 32px;
        @media only screen and (max-width:1023px) {
            font-size: 28px;
        }
    }
    h3 {
        font-family:var(--CircularB);
        font-size:21px;
        line-height:1.5em;
        text-align:center;
        font-weight:400;
        margin-top:0;
        color: var(--ardoise);
        @media only screen and (max-width:1023px) {
            font-size: 16px;
        }
    }
    h2, h3 {
        text-align:left;
    }
    button {
        .primary, .secondary {
            min-width: 100% !important;
            margin-right: 0% !important;
            margin-left: 0% !important;
        }
        .secondary {
            background-color: var(--perle) !important;
            &:not(:hover) {
                border-color: var(--perle) !important;
                box-shadow: 0px 0px 8px rgba(0,0,0,0.24);
            }
        }
    }
    .title-like-img {
        height:64px !important;
        margin: unset;
        margin-bottom: 16px;
        @media only screen and (max-width:1023px) {
            margin: auto;
            margin-bottom: 24px;
            height: 48px !important;
        }
    }
    .button-container {
        max-width: fit-content;
        @media only screen and (max-width:1023px) {
            max-width: initial;
        }
    }
    #hero-pic {
        margin: 24px;
        flex: 0 1 auto;
        img {
            max-width: 100%;
        }
    }
    #hero-desc {
        @media only screen and (min-width: 1024px) {
            flex: 0 1 auto;
            min-width: 440px;
            max-width: 640px;
        }
    }
}

/*Hiding the tyeform original link*/
#typeform-embed-form {
    display:none;
}

/*Bottom promo banner styles*/
#bottom-promo {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 16px 32px;
    z-index: 1000;
    background-color: var(--myrtille);
    display: none;
    flex-flow: column nowrap;
    justify-content:center;
    align-items:center;
    opacity: 100%;
    transition: opacity .48s;
    box-shadow: 0 0 4px rgba(0,0,0,0.32);
    @media only screen and (max-width:727px) {
        padding: 4px 4px;
        text-align:center;
    }
    * {
        margin: auto 8px;
        @media only screen and (max-width:727px) {
            margin: 4px auto;
        }
    }
    & > * {
        display: flex;
        flex-flow: row nowrap;
        width: 100%;
        justify-content: center;
        align-items: center;
        @media only screen and (max-width:727px) {
            flex-flow: column nowrap;
            align-items: space-between;
        }
    }
    button {
        color: var(--myrtille) !important;
        background-color: var(--perle) !important;
        border: 1px solid var(--perle) !important;
        &:hover {
            box-shadow: 0px 0px 4px rgba(255,255,255,0.32);
            box-shadow: inset 0px 0px 4px rgba(0,0,0,0.32);
        }
    }
    p {
        color: var(--perle);
        font-size:16px;
    }
}
#bottom-promo-content {
    margin: 0 !important;
}

/*Styles for product carousel*/
.product-carousel {
    display: flex;
    flex-flow: row nowrap;
    align-items:center;
    justify-content: center;
    margin:auto;
    max-width: calc(960px + 48px - 16px + 48px + 40px);
    margin-top: 8px;
    @media only screen and (max-width: 1023px) {
        margin-top: auto;
    }
    .previous, .next {
        width: 36px;
        height: 36px;
        @media only screen and (max-width: 1023px) {
            height: 24px;
            width: 24px;
        }
        @media (hover: hover) {
            &:hover {
                filter: invert(73%) sepia(39%) saturate(3699%) hue-rotate(322deg) brightness(98%) contrast(95%);
                cursor: pointer;
            }
        }
    }
    .previous {
        margin-right: 40px;
        @media only screen and (max-width: 1023px) {
            margin-right: 0px;
        }
    }
    .next {
        margin-left: 40px;
        @media only screen and (max-width: 1023px) {
            margin-left: 0px;
        }
    }
    .all-carousel-cards {
        opacity: 1;
        transition: opacity 0.48s ease-in-out;
    }
    //This is where it needs refacto for multi-offer display
    .carousel-card {
        display: none;
        .col {
            margin-left:16px;
            margin-right: 16px;
            @media only screen and (max-width: 1023px) {
                margin: 16px auto !important;
            }
        }
        .double-layout {
            .col {
                &:first-of-type {
                    margin-left: -16px !important;
                    @media only screen and (max-width: 1023px) {
                        margin-left: auto !important;
                    }
                }
                &:last-of-type {
                    margin-right: -16px !important;
                    @media only screen and (max-width: 1023px) {
                        margin-right: auto !important;
                    }
                }
                img {
                    object-fit: contain;
                    height: auto;
                }
                .title-like-img {
                    height: 40px !important;
                }
            }
        }
    }
    div:first-of-type {
        display:flex;
    }
}
.opacity-transition {
    opacity: 0 !important;
    transition: opacity 0.48s ease-in-out !important;
}

.product-carousel .pricing-card { //tried blind fix by switching to class instead of ID, seems to work.
display: block;
margin-top: auto;
}

.nz-edu-section {
    --page-primary: var(--navet);
}