.columns-container {
    display: flex;
    flex-flow: row nowrap;
    max-width:1440px;
    margin:auto;
}

.fluid-width-video-wrapper {
    padding: none !important;
}
#article-header-container {
    width:100%;
    position: relative;
    img {
        width : 100%;
        height: 60vh;
        object-fit: cover;
        object-position: 0px 66%;
        @media (max-width:1023px) {
            max-height: 250px;
        }
    }
    &::after {
        content: "";
        width: 100%;
        position: absolute;
        background-image: url('../images/ivory_background.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        height: 15%;
        bottom: 0;
        right: 0;
        left: 0;
    }
}
#main-article {
    display :flex;
    flex-direction : row;
    background-color: white;
    margin-top: 60px;
    @media (max-width:1023px) {
        width:87%;
        max-width:960px;
        margin:auto;
        margin-top: 30px;
    }
}
.column-right-sticky-content,.column-left-sticky-content {
    position:sticky;
    top:100px;
}
.column-left {
    display: flex;
    flex-flow: column nowrap;
    flex : 0.33;
    margin-right:16px;
    margin-left: -16px;
    padding: 32px 40px;
    justify-content: flex-start;
    align-items: flex-start;
    @media (max-width:1023px) {
        display: none;
    }
    & > *:first-child > *:first-child {
        margin-top: 0px;
    }
    h2, p {
        font-family: var(--Zilla);
    }
    .title-like-img {
        margin-left: 0;
        height: auto;
        max-height: 40px;
        max-width: 100%;
    }
}
.column-center {
    flex : 1;
    width: 100%;
    a {
        font-size: inherit;
    }
    hr {
        width: 100%;
        height: 8px;
        background-color: var(--incarnat);
        margin-top: 64px;
        margin-bottom: 16px;
        border: none;
    }
    h1, h2, h3 {
        font-family: var(--Flex);
    }
    h1 {
        font-size: 48px;
        @media (max-width:1023px) {
            font-size: 32px;
        }
    }
    h2 {
        margin-bottom: 1em;
        margin-top: 1em;
        @media (max-width:1023px) {
            font-size: 24px;
        }
    }
    h3 {
        font-size: 26px;
        font-weight: 600;
        @media (max-width:1023px) {
            font-size: 18px;
            margin-bottom: 6px;
        }
    }
    p {
        font-size: 18px;
        font-weight: 300;
        line-height: 1.5em;
        @media (max-width:1023px) {
            font-size: 16px;
        }
    }
    .article-head-date {
        font-family: var(--CircularB);
        font-size: 16px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.48);
        @media (max-width:1023px) {
            font-size: 14px;
        }
    }
    .article-text p:first-of-type {
        font-style: italic !important;
    }
    blockquote {
        width: 100%;
        position: relative;
        margin: auto 0;
        * {
            font-family: var(--Zilla);
            font-size: 21px;
            font-weight: 600;
            font-style: italic;
            color: var(--incarnat);
            @media (max-width:1023px) {
                left: 0px;
                font-size: 18px;
            }
        }
    }
    .video-wrapper {
        height: 0px;
        width: 100%;
        object-fit:cover;
        margin:auto;
        margin-bottom: 40px;
        iframe {
            display: none;
            @media (max-width:1023px) {
                display: block;
            }
        }
        @media (max-width:1023px) {
            height: auto;
        }
    }
    .video-play {
        position: relative;
        left: -20%;
        top: -120px;
        width: 80px;
        height: 48px;
        border-radius: 7px;
        background-color: rgba(231, 79, 44, 0.25);
        cursor: pointer;
        background-image: url("../images/youtube-icon-full-color.png");
        background-size: auto 28px;
        background-repeat: no-repeat;
        background-position: center;
        @media (max-width:1023px) {
            display: none;
        }
    }
    .video-stop {
        display:none;
        position: relative;
        left: -20%;
        top: -100px;
        width: 80px;
        height: 48px;
        border-radius: 7px;
        background-color: rgba(231, 79, 44, 0.25);
        cursor: pointer;
        background-image: url(youtube-stop-icon.png);
        background-size: 24px 18px;
        background-repeat: no-repeat;
        background-position: center;
        @media (max-width:1023px) {
            display: none;
        }
    }
    .article-gallery .article-secondary h1 {
        margin-bottom: 0;
    }


    // A basic 2 column grid to display 2 images side-by-side
    p.images-grid, div.images-grid, %default-images-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        @media  (max-width: 1023px) {
            grid-template-columns: 1fr;    
        }
        column-gap: 16px;
        row-gap: 16px;
    }

    // Centers the first left <p> of the grid.
    // Use for iPad stands article
    .left-center-images-grid {
        @extend %default-images-grid;
        & > p {
            text-align: center;
        }
    }

    .left-portrait-grid { // Used for Women's composer article
        @extend %default-images-grid;
        grid-template-columns: 200px 1fr; 
        @media  (max-width: 468px) {
            grid-template-columns: 1fr;    
        }
        & > p {
            text-align: center;
            margin-bottom: 0;
        }
        img {
            max-width: 200px;
        }
    }

    .cta-link {
        text-align: center;
    }
}
.column-right {
    display: flex;
    flex-flow: column nowrap;
    flex : 0.2;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    padding: 16px 32px 16px 40px;
    @media (max-width:1023px) {
        display: none;
    }
    h2 {
        font-family: var(--Zilla);
        font-size: 16px;
        font-weight: 400;
        text-align:center;
    }
    h3 {
        margin : 0;
        font-size: 16px;
        line-height: 1.75;
        text-align: center;
        margin-bottom: -8px;
    }
    p {
        font-size: 14px;
        text-align: center;
        color: rgba(0, 0, 0, 0.5);
    }
    img {
        width: 60px;
        height: 60px;
        border-radius: 100%;
    }
    .progress-ring__circle {
        transition: 0.35s stroke-dashoffset;
        transform: rotate(-90deg);
        transform-origin: 50% 50%;
    }
}

#readingTime, #reading-time-minutes {
    font-family: var(--Zilla);
    font-weight: 500;
}

.share_buttons {
    display: flex;
    @media (max-width:1023px) {
        flex-flow: row nowrap;
        margin-bottom: 24px;
        & > * {
            margin: auto 16px;
        }
        & > *:first-child {
            margin-left: 0px;
        }
        & > *:last-child {
            margin-right: 0px;
        }
    }
    justify-content:flex-start;
    align-items: baseline;
    @media (min-width: 1024px) {
        flex-flow: column nowrap;
        justify-content:center;
        align-items: center;
    }
}