/*Color palette: only use these references in subsequent code to make sure colors are easy to maintain*/
:root {
/*Primary palette below*/
--encre: #000;
--anthracite: #141414;
--charbon: #292828;
--graphite: #3D3C3C;
--ardoise: #666564;
--chartreux: #8F8D8C;
--souris: #B8B5B4;
--tourterelle: #E0DDDC;
--selene: #F5F3F2;
--argent: #FAF8F7;
--perle: #FFF;
--raisin: #3D2535;
--pourpre: #66455B;
--prune: #8F617F;
--navet: #B87CA4;
--parme: #CC9BBC;
--lin: #EBD8E4;
--chardon: #F7F2F6;
--terracotta: #521803;
--rouille: #A34421;
--corail: #E05D2D;
--incarnat: #f58258;
--peche: #FAB196;
--guimauve: #FCE6DE;
--diaphane: #FCF5F2;
/*Secondary palette below*/
--bordeaux: #3D1123;
--amarante: #7A2C4B;
--fuschia: #C25D85;
--candy: #F593BA;
--tagada: #F5BAD2;
--dragee: #F5DCE6;
--chair: #FCF5F8;
--moka: #3D3202;
--tabac: #7A650F;
--miel: #CCAC29;
--gold: #FFD633;
--ble: #FCE483;
--paille: #FAF0C8;
--pastis: #FAF6E6;
--matcha: #393D02;
--cornichon: #6E750E;
--mousse: #AEB825;
--kiwi: #D7E051;
--avocat: #E5EB96;
--amande: #EDF0C9;
--melon: #F6F7E9;
--malachite: #022E25;
--emeraude: #0F7A64;
--colvert: #25B89A;
--aiguemarine: #74E0CA;
--surf: #BAF5E9;
--celadon: #DCF5F0;
--menthe: #F2FAF8;
--marine: #1B293D;
--petrole: #36517A;
--acier: #658AC2;
--cadet: #7FAEF5;
--bleuet: #AFCDFA;
--guede: #DBEAFF;
--ecume: #F2F7FF;
/*Functional palette below*/
--sepia: #3D1202;
--carmin: #7A2405;
--garance: #B83707;
--vermillon: #E0501B;
--cinabre: #F57445;
--grenadine: #F5D8CE;
--rose: #FFF8F5;
--chocolat: #3D2202;
--chataigne: #7A4405;
--cuivre: #CC7818;
--safran: #F5AF24;
--camel: #F5CE7F;
--sable: #F5E1BA;
--ecru: #FFFCF5;
--sapin: #212902;
--anglais: #425203;
--olive: #788F1D;
--pomme: #AFCC39;
--tilleul: #D8EB8D;
--pistache: #EDF5CE;
--glauque: #FDFFF5;
--outremer: #022A3D;
--prusse: #0F567A;
--france: #2587B8;
--azur: #50B0E0;
--celeste: #A6DBF5;
--versailles: #D6FAFF;
--glacier: #F5FCFF;
--indigo: #02023D;
--aubergine: #22227A;
--myrtille: #4242B8;
--iris: #6C6CF5;
--lavande: #A7A7F5;
--lilas: #CECEF5;
--amethyste: #F5F5FA;
/*Background palette*/
--bg-menu: rgba(255,255,255, 0.8);
--bg-submenu: rgba(255, 255, 255, 0.985);
}