/*––––– Design sytem styles –––––*/

/*––––– Fonts –––––*/

/*Euclid Circular B*/
/* Normal */
@font-face {
    font-family: 'Euclid Circular B';
    src: url('../fonts/EuclidCircularB-Regular-WebS.woff2') format('woff2');
    font-style: normal;
    font-weight: 400;
    text-rendering: optimizeLegibility;
    font-display: swap;
}
/* Italic */
@font-face {
    font-family: 'Euclid Circular B';
    src: url('../fonts/EuclidCircularB-RegularItalic-WebS.woff2') format('woff2');
    font-style: italic;
    font-weight: 400;
    text-rendering: optimizeLegibility;
    font-display: swap;
}
/* Semibold */
@font-face {
    font-family: 'Euclid Circular B';
    src: url('../fonts/EuclidCircularB-Semibold-WebS.woff2') format('woff2');
    font-style: normal;
    font-weight: 600;
    text-rendering: optimizeLegibility;
    font-display: swap;
}
/* Semibold + Italic */
@font-face {
    font-family: 'Euclid Circular B';
    src: url('../fonts/EuclidCircularB-SemiboldItalic-WebS.woff2') format('woff2');
    font-style: italic;
    font-weight: 600;
    text-rendering: optimizeLegibility;
    font-display: swap;
}
/* Bold*/
@font-face {
    font-family: 'Euclid Circular B';
    src: url('../fonts/EuclidCircularB-Bold-WebS.woff2') format('woff2');
    font-style: normal;
    font-weight: 700;
    text-rendering: optimizeLegibility;
    font-display: swap;
}
/* Bold + Italic */
@font-face {
    font-family: 'Euclid Circular B';
    font-family: 'Euclid Circular B';
    src: url('../fonts/EuclidCircularB-BoldItalic-WebS.woff2') format('woff2');
    font-style: italic;
    font-weight: 700;
    text-rendering: optimizeLegibility;
    font-display: swap;
}
/* Light */
@font-face {
    font-family: 'Euclid Circular B';
    src: url('../fonts/EuclidCircularB-Light-WebS.woff2') format('woff2');
    font-style: normal;
    font-weight: 300;
    text-rendering: optimizeLegibility;
    font-display: swap;
}
/* Light + Italic*/
@font-face {
    font-family: 'Euclid Circular B';
    src: url('../fonts/EuclidCircularB-LightItalic-WebS.woff2') format('woff2');
    font-style: italic;
    font-weight: 300;
    text-rendering: optimizeLegibility;
    font-display: swap;
}
/* Medium */
@font-face {
    font-family: 'Euclid Circular B';
    src: url('../fonts/EuclidCircularB-Medium-WebS.woff2') format('woff2');
    font-style: normal;
    font-weight: 500;
    text-rendering: optimizeLegibility;
    font-display: swap;
}
/* Medium + italic */
@font-face {
    font-family: 'Euclid Circular B';
    src: url('../fonts/EuclidCircularB-MediumItalic-WebS.woff2') format('woff2');
    font-style: italic;
    font-weight: 500;
    text-rendering: optimizeLegibility;
    font-display: swap;
}

/* Euclid Flex */
/*Bold*/
@font-face {
    font-family: 'Euclid Flex';
    src: url('../fonts/EuclidFlex-Bold-WebS.woff2') format('woff2');
    font-style: normal;
    font-weight: 700;
    text-rendering: optimizeLegibility;
    font-display: swap;
}
/*Bold + Italic*/
@font-face {
    font-family: 'Euclid Flex';
    src: url('../fonts/EuclidFlex-BoldItalic-WebS.woff2') format('woff2');
    font-style: italic;
    font-weight: 700;
    text-rendering: optimizeLegibility;
    font-display: swap;
}
/* Semibold */
@font-face {
    font-family: 'Euclid Flex';
    src: url('../fonts/EuclidFlex-Semibold-WebS.woff2') format('woff2');
    font-style: normal;
    font-weight: 600;
    text-rendering: optimizeLegibility;
    font-display: swap;
}
/* Semibold */
@font-face {
    font-family: 'Euclid Flex';
    src: url('../fonts/EuclidFlex-SemiboldItalic-WebS.woff2') format('woff2');
    font-style: italic;
    font-weight: 600;
    text-rendering: optimizeLegibility;
    font-display: swap;
}

/*Muli/Mulish fallback*/
@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600&display=swap');

/*Zilla Slab for quotes*/
/* Normal */
@font-face {
    font-family: 'Zilla Slab';
    src: url('../fonts/ZillaSlab-Regular.woff2') format('woff2');
    font-style: normal;
    font-weight: 400;
    text-rendering: optimizeLegibility;
    font-display: swap;
}
/* Italic */
@font-face {
    font-family: 'Zilla Slab';
    src: url('../fonts/ZillaSlab-Italic.woff2') format('woff2');
    font-style: italic;
    font-weight: 400;
    text-rendering: optimizeLegibility;
    font-display: swap;
}
/* Semibold */
@font-face {
    font-family: 'Zilla Slab';
    src: url('../fonts/ZillaSlab-SemiBold.woff2') format('woff2');
    font-style: normal;
    font-weight: 600;
    text-rendering: optimizeLegibility;
    font-display: swap;
}
/* Semibold + Italic */
@font-face {
    font-family: 'Zilla Slab';
    src: url('../fonts/ZillaSlab-SemiBoldItalic.woff2') format('woff2');
    font-style: italic;
    font-weight: 600;
    text-rendering: optimizeLegibility;
    font-display: swap;
}
/* Medium */
@font-face {
    font-family: 'Zilla Slab';
    src: url('../fonts/ZillaSlab-Medium.woff2') format('woff2');
    font-style: normal;
    font-weight: 500;
    text-rendering: optimizeLegibility;
    font-display: swap;
}
/* Medium + italic */
@font-face {
    font-family: 'Zilla Slab';
    src: url('../fonts/ZillaSlab-MediumItalic.woff2') format('woff2');
    font-style: italic;
    font-weight: 500;
    text-rendering: optimizeLegibility;
    font-display: swap;
}

:root {
    --CircularB: "Euclid Circular B", "Mulish", "Arial", sans-serif;
    --Flex: "Euclid Flex", "Mulish", "Arial", sans-serif;
    --Zilla: "Zilla Slab", "Euclid Circular B", "Mulish", "Arial", serif;
}

/*——————— Backgrounds —————*/
.bg-accent {
    background-color:var(--bg-accent);
    background-image: url(/images/sheet-music-texture.jpeg);
    background-size: cover;
    background-blend-mode: screen;
}
.bg-default {
    background-color:var(--perle);
}

:root {
    --bg-accent: var(--selene);
}

/*————— Typography —————*/
h1, h2, h3, h4 {
    font-family: var(--Flex);
    margin-block-start: 0;
    margin-block-end: 0;
}
h1 {
    font-size: 32px;
    margin-bottom: 20px;
    font-weight: 600;
    color: var(--encre);
    @media only screen and (max-width:1023px) {
        font-size:28px;
    }
}
h2 {
    font-size: 28px;
    margin-bottom: 20px;
    font-weight: 600;
    @media only screen and (max-width:1023px) {
        font-size: 24px;
    }
}
h3 {
    font-size: 24px;
    margin-bottom: 16px;
    font-weight: 500;
}
h4 {
    font-size: 21px;
    font-weight: 700;
}
p, ul, li {
    font-family:  var(--CircularB);
    font-size: 18px;
    line-height: 24px;
    color: var(--encre);
    font-weight: 300;
    margin-top:16px;
    margin-bottom:16px;
    line-height: 1.33;
}
ul {
    padding-left:16px;
}
header {
    p, ul, li {
        font-weight:400;
    }
}
em {
    font-style: italic;
}
b {
    font-weight:600;
}
.title-like-img {
    height: 48px;
    margin:auto;
    margin-bottom:24px;
    display:block;
}
.light {
    color: var(--perle);
}
.text-secondary {
    opacity: 0.5;
}
footer .fab {
    margin-right: 8px;
}
a {
    text-decoration:none;
    color: var(--page-primary);
}


/*————— Structure / different section options —————*/
body {
    background-color: var(--perle);
    margin: 0;
}
header, section {
    padding-top:48px;
    padding-bottom:48px;
    @media only screen and (max-width:1023px) {
        padding-top:40px;
        padding-bottom:40px;
    }
}
footer {
    padding-top:24px;
    padding-bottom:24px;
    background-color:var(--selene);
    max-width:100%;
    @media only screen and (max-width:1023px) {
        padding-top:16px;
        padding-bottom:16px;
    }
}
.hero {
    h1 {
        font-size: 36px; // TODO: review typography here
        text-align:center;
        @media only screen and (max-width:1023px) {
            font-size: 36px;
        }
        @media only screen and (max-width:1023px) {
            font-size: 32px;
        }
    }
    h2 {
        text-align:center;
        max-width:768px;
        margin-left:auto;
        margin-right:auto;
        @media only screen and (max-width:1023px) {
            font-size: 28px;
        }
    }
    p {
        font-size:21px;
        line-height: 24px;
        @media only screen and (max-width:1023px) {
            font-size: 18px;
            line-height: 24px;
        }
    }
    li {
        font-size: 21px;
    }
    button {
        .primary, .secondary {
            min-width:50%;
            margin-right:25%;
            margin-left:25%;
            @media only screen and (max-width:1023px) {
                margin-left:auto;
                margin-right:auto;
            }
        }
        .tertiary {
            font-size:24px;
            @media only screen and (max-width:1023px) {
                font-size: 18px;
            }
        }
    }
}
.double-layout {
    width:100%;
    max-width:960px;
    margin: auto;
    display:flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: space-between;
    @media only screen and (max-width:1023px) {
        width:auto;
        padding-right:16px;
        padding-left:16px;
        flex-flow:column nowrap;
        max-width:100%;
        object-fit: contain;
        & > * {
            margin: 24px auto;
        }
    }
}
.col {
    max-width: 440px;
    object-fit: contain;
    align-self:center;
    @media only screen and (max-width:1023px) {
        max-width: 100%;
    }
    img, .col picture {
        max-width: 100%;
    }
}
.single-layout {
    max-width:960px;
}
.single-layout-small {
    max-width:728px;
}
.single-layout, .single-layout-small {
    margin:auto;
    object-fit: contain;
    @media only screen and (max-width:1023px) {
        width: auto;
        padding-right: 16px;
        padding-left: 16px;
    }
    img, picture {
        max-width: 100%;
        border-radius: 7px;
    }
}

footer hr {
    margin-top:32px;
    margin-bottom:32px;
    border: 1px solid rgba(0,0,0,0.16);
}
#footer-socials {
    a, i {
        text-decoration: none;
        color: var(--encre);
    }
}
#legal-mentions {
    p, a {
        color: var(--ardoise);
        font-size:12px;
        margin: 0 8px;
    }
    a {
        text-decoration: underline;
        margin: 0;
    }
}
.footer-col-container {
    display:grid;
    align-items:flex-start;
    grid-template-columns: repeat(4, 1fr);
    @media only screen and (max-width:727px) {
        grid-template-columns: auto;
    }
}
.footer-row-container {
    display:flex;
    flex-flow:row nowrap;
    justify-content: space-between;
    align-items:center;
    @media only screen and (max-width:560px) {
        flex-flow: column nowrap;
        align-items: flex-start;
        & > * {
            margin-top: 8px;
            margin-bottom: 8px;
        }
    }
}
.footer-row {
    display:flex;
    flex-flow: row wrap;
    justify-content: space-between;
    &:first-child {
        margin-left: 0px !important;
    }
    &:last-child {
        margin-right: 0px !important;
    }
    @media only screen and (max-width:560px) {
        flex-flow: column nowrap;
    }
}
@media only screen and (min-width: 560px) {
    #last-footer-section > * {
        margin: auto 16px;
        &:first-child {
            margin-left: 0px !important;
        }
        &:last-child {
            margin-right: 0px !important;
        }
    }
}
.footer-col {
    h6 {
        font-family: var(--CircularB);
        font-size:16px;
        line-height:21px;
        font-weight: 600;
        color: var(--encre);
        margin:0;
        margin-bottom:8px;
        @media only screen and (max-width:727px) {
            margin-top:24px;
        }
    }
    @media only screen and (max-width:727px) {
        &:first-of-type h6 {
            margin-top:0px;
        }
    }
    p, a {
        text-decoration: none;
        font-family: var(--CircularB);
        line-height:21px;
        font-size:16px;
        font-weight:400;
        color: var(--encre);
    }
}
#footer-logo {
    height:32px;
    object-fit: contain;
}
.lang-selector {
    display:flex;
    flex-flow: row nowrap;
    align-items:center;
    border: 1px solid rgba(0,0,0,0.24);
    border-radius:5px;
    padding: 8px;
    * {
        font-family: var(--CircularB);
        font-size:16px;
        margin: auto 4px;
        width: 100%;
        @media only screen and (max-width: 1023px) {
            text-align: center;
        }
    }
    select {
        background-color: transparent;
        border:none;
        outline: none;
    }
}
.lang-selector-flag {
    @extend .lang-selector;
    * {
        font-size: 21px;
        padding-right: 4px !important;
    }
    border: none;
    margin: 0 !important;
}
.currency-selector-container {
    max-width:432px;
    margin:auto;
    margin-top:8px;
    font-style:italic;
    color:rgba(0,0,0,0.48);
}
.currency-selector {
    display: flex;
    align-items: center;
    width: fit-content;
    margin: auto auto 16px auto;
    select {
        outline: none;
        display:flex;
        flex-flow: row nowrap;
        align-items: center;
        border: 1px solid rgba(0,0,0,0.24);
        border-radius: 5px;
        padding: 4px 8px;
        margin-left: 8px;
    }
    p {
        font-size: 14px;
        margin:unset;
    }
}

/*FAQ styles*/
.faq {
    h2 {
        font-size: 28px;
        font-weight: 600;
    }
    h3 {
        font-size: 18px;
        font-weight: 400;
        font-family: var(--CircularB);
        margin-bottom: -8px;
        cursor: pointer;
    }
    .faq-answer {
        margin-top: 8px;
        margin-bottom: 8px;
        p {
            font-size: 16px;
            overflow: hidden;
            max-height:0px;
            transition: all 0.24s ease-out, margin-top 0.24s ease-in, margin-bottom 0.24s ease-in;
            margin-bottom: 0px;
            margin-top: 0px;
        }
        &.open p {
            max-height: 500px !important;
            margin-bottom: 8px;
            margin-top: 16px;
            transition: all 0.56s ease-in, margin-top 0.24s ease-in, margin-bottom 0.24s ease-in;
        }
    }
}
.faq-entry {
    h3 {
        &:first-of-type::before {
            content: url(../icons/faq-chevron-up.svg);
            position: relative;
            top:6px;
            display: inline-block;
            transition: all 0.16s linear;
        }
        &.open::before {
            transform-origin: 50% 41%;
            transform: rotate(180deg) !important;
            transition: all 0.16s linear;
        }
    }
}

.parallax-divider {
    width: 100vw;
    min-height: 64vh;
    background-attachment: fixed;
    background-position: 48% 20%;
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
    z-index:-1;
    @media only screen and (max-width:1023px) {
        background-attachment: initial;
    }
    @media only screen and (max-width:880px) {
        min-height: 55vh;
    }
    @media only screen and (max-width:588px) {
        min-height: 33vh;
    }
}

/*————— Components —————*/

/*Navigation menu*/
#main-menu {
    position:fixed;
    z-index:100000;
    top:0;
    left:0;
    right:0;
    margin:0;
    padding-right:32px;
    padding-left:32px;
    height:80px;
    display:flex;
    flex-flow: row nowrap;
    justify-content:space-between;
    align-items:center;
    background-color: var(--bg-menu);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    box-shadow:0 0 4px rgba(0,0,0,0.16);
    @media only screen and (max-width:1023px) {
        height:56px;
        padding-right:24px;
        padding-left:24px;
        flex-flow: column nowrap;
        justify-content: flex-start;
        overflow: hidden;
        transition: height .3s ease .18s;
        .lang-selector {
            position: absolute;
            bottom: 16px;
            width: calc(100% - 65px); /* 2 x 32px - 1px to account for border*/
        }
    }
}
#main-menu-mobile {
    display:none;
    @media only screen and (max-width:1023px) {
        display:flex;
        flex-flow: row nowrap;
        min-height:56px;
        align-items:center !important;
        justify-content: space-between;
        width:100%;
        & > button {
            margin-top:0;
            margin-bottom:0;
            min-height:56px;
        }
    }
}
#menu-burger {
    display: none;
    @media only screen and (max-width:1023px) {
        display:flex;
        flex-flow:column nowrap;
        cursor:pointer;
        height:56px;
        width:64px;
        align-items: center;
        justify-content: center;
        position:absolute;
        left:0px;
    }
}
@media only screen and (max-width:1023px) {
    #burger-top-line {
        margin-bottom:3px;
        transition: margin-bottom 0.3s cubic-bezier(0.04, 0.04, 0.12, 0.96) 0.3s, transform .1806s cubic-bezier(0.04, 0.04, 0.12, 0.96) .1s;
        cursor:pointer;
    }
    #burger-bottom-line {
        margin-top:3px;
        transition: margin-top 0.3s cubic-bezier(0.04, 0.04, 0.12, 0.96) 0.3s, transform .1806s cubic-bezier(0.04, 0.04, 0.12, 0.96) .1s;
        cursor:pointer;
    }
    #main-menu {
        .top-line-transform, .bottom-line-transform {
            transition: margin-bottom .3s cubic-bezier(0.04, 0.04, 0.12, 0.96), transform .3s cubic-bezier(0.04, 0.04, 0.12, 0.96) .2s;
            margin-bottom:-4px;
        }
        .top-line-transform {
            transform: rotate(45deg);
        }
        .bottom-line-transform {
            transform: rotate(-45deg);
        }
    }
    .menu-open-style {
        height: 100vh;
        height: calc(100 * var(--vh)) !important;
    }
    nav > * > .lang-selector, #mobile-menu-main-cta {
        z-index: -1;
        opacity: 0%;
        transition: 0.24s linear all;
    }
    nav.menu-open-style > * > .lang-selector, nav.menu-open-style > * > #mobile-menu-main-cta {
        z-index: 1;
        opacity: 100%;
        transition: 0.24s 0.24s linear all;
    }
}
#menu-newzik-logo {
    height:32px;
    object-fit: contain;
    float: left;
    cursor:pointer;
}
#menu-full-logo, #menu-small-logo {
    height:32px;
}
#menu-small-logo {
    display:none;
}
@media only screen and (max-width:1023px) {
    #menu-full-logo {
        display:none;
    }
    #menu-small-logo {
        display:block;
        position:absolute;
        left:calc(50% - 16px);
    }
}

// Mixin used for the "Log in" button
// in the desktop and mobile menu
// Same color as secondary buttons, but without
// the ugly weight change on hover and the animation
@mixin login-button-menu {
    a {
        color: var(--page-primary);
    }
    &:hover {
        &, a:hover{
            font-weight: unset;
        }
        box-shadow:0 0 4px rgba(0,0,0,0.16);
    }
}

#main-menu-entries {
    display:flex;
    flex-flow:row nowrap;
    align-items:center;
    float:right;
    overflow: scroll;
    button, h5, a {
        font-family: var(--CircularB);
        font-weight:400;
        font-size:16px;
        color: var(--encre);
        text-decoration: none;
    }
    h5, a {
        &:hover {
            font-weight:600;
        }
    }
    & > * {
        margin:0 16px;
        cursor:pointer;
    }
    &:first-child {
        margin-left:-16px;
    }
    &:last-child {
        margin-right:-16px;
    }
    @media only screen and (min-width:1024px) {
        overflow: visible;
    }
    @media only screen and (max-width:1023px) {
        flex-flow: column nowrap;
        align-items: flex-start;
        justify-content: flex-start;
        float: none;
        width:100%;
        & > * {
            margin: 8px 0px;
        }
        &:first-child {
            margin-left:0px;
        }
        &:last-child {
            margin-right:0px;
        }
    }

    #desktop-login-button {
        @include login-button-menu();
    }
}

#mobile-primary-cta {
    position:absolute;
    right:16px;
    color: var(--incarnat) !important;
    @media only screen and (max-width:1023px) {
        float:right;
        background-color:transparent !important;
        font-size:16px !important;
        border: none !important;
        padding:0 0;
    }
}
#mobile-menu-main-cta {
    position: absolute;
    bottom: 72px;
    width: calc(100% - 47px);
}
#mobile-login-button {
    position: absolute;
    // [gboya] Leaving a TODO here, because this whole mobile menu
    // stuff is a clusterf*ck of broken CSS.
    // The "bottom" buttons inside a flex container, but are actually
    // using absolute positioning relative to the bottom of the body,
    // which is pointless.
    // Reworking the positioning (which we should do) involves changing
    // the other deeply nested selectors on these elements (L740 sqq)
    // 72px + 48 (height of the #main-menu-main-cta) + 16px spacing
    bottom: 136px;
    width: calc(100% - 47px);
    @include login-button-menu();
    // Small hack so that the whole button is clickable,
    // which would not be the case if the <button> had padding
    padding: 0;
    display: flex;
    & > * {
        flex-grow: 1;
        padding: 11px 16px 13px 16px;
    }
}

@media only screen and (max-width:1023px) {
    .menu-cta:hover {
        box-shadow:none !important;
    }
    .menu-cta:active {
        box-shadow:none !important;
    }
}
.sub-menu {
    display:none;
    flex-flow:column nowrap;
    padding-left:24px;
    align-items: flex-start;
    overflow:hidden;
    transition:height 0.18s ease-out;
    height:0px;
    margin: -4px 0 0 0 !important;
    h6, a {
        font-family: var(--CircularB);
        font-weight: 400;
        font-size: 14px;
        margin: 8px 0;
    }
    @media only screen and (min-width:1023px) {
        display:none;
        position: absolute;
        top: 84px;
        background-color: var(--bg-submenu);
        backdrop-filter: blur(20px);
        -webkit-backdrop-filter: blur(20px);
        box-shadow:0 4px 4px rgba(0,0,0,0.16);
        padding: 8px 24px 0px 24px;
        border-radius:0 0 5px 5px;
        h6 {
            font-size:16px;
            margin: 8px 0;
        }
    }
}
.menu-cta {
    color: var(--incarnat) !important;
}
button.menu-cta {
    color: var(--perle) !important;
    background-color: var(--incarnat) !important;
    border: var(--incarnat) !important;
}
.body-lock-scroll {
    overflow:hidden;
}
.sub-menu-entry {
    &::after {
        content:'▾';
        margin-left:8px;
    }
    @media only screen and (min-width:1023px) {
        &::after {
            position:relative;
            top:-2px;
            transition: all .2s linear;
        }
        &:hover::after {
            top: 1px;
        }
    }
}

//styles for dark-menu => set class 'dark-enabled' in JS to main-menu on pages where this is wanted. Optionnally, can be removed after some scroll to only affect the hero part (eg: Maestria page)
#main-menu.dark-enabled:not(.dark) {
    background-color: transparent;
    h5, a {
        color: var(--perle) !important;
    }
}
.dark-enabled {
    .sub-menu {
        background-color:#1F252C;
    }
}

#main-menu.dark-enabled {
    .dark {
        .sub-menu {
            background-color:var(--perle);
        }
    }
}

/*dealing with main-content first child to adjust padding according to menu height*/
#main-content > *:first-child {
    padding-top: 120px;
    @media only screen and (max-width:1023px) {
        padding-top: 88px;
    }
}

.pop-up-card {
    position: fixed;
    z-index: 100001;
    left:0;
    right:0;
    top:0;
    bottom:0;
    background-color: rgba(0,0,0,0.24);
    backdrop-filter: blur(24px);
    height:100vh;
    width:100vw;
    display:flex;
}
.pop-up-card-inner {
    width: fit-content;
    height: fit-content;
    padding: 32px;
    border-radius:7px;
    background-color: var(--perle);
    margin:auto;
    position: relative;
    top:0;
    bottom:0;
    box-shadow: 0 0 32px 8px rgba(0,0,0,0.24);
    h2 {
        text-align:center;
    }
    a {
        text-decoration:none;
        width: 100%;
    }
}

/*cinema video player*/
#video-player-background {
    position: fixed;
    background-color: rgba(0,0,0,0.88);
    width:100vw;
    height: 100vh;
    height: calc(100*var(--vh));
    top:0;
    z-index: 100001;
    display: none;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
}
#video-player-close-button {
    height: 24px;
    width: 24px;
    cursor: pointer;
    position: relative;
    filter: invert(100%);
    align-self: flex-end;
    left: calc( -14vw + 36px);
    top: calc(14 * var(--vh) - 24px);
    @media only screen and (orientation: portrait) {
        align-self: flex-end;
        left: -5vw;
        top: calc(33 * var(--vh) - 16px);
    }
}
#cinema-video-player {
    width: 100%; 
    max-width:72vw; 
    margin:auto; 
    height: 100%;
    max-height: calc(72 * var(--vh)); 
    object-fit: contain;
    @media only screen and (orientation: portrait) {
        max-width: 90vw;
        height: calc(33 * var(--vh));
    }
}
.stop-scrolling {
    height: 100%;
    overflow: hidden;
}

/*Buttons*/
button {
    min-width: -moz-fit-content;
    min-width:fit-content;
    padding:4px 16px;
    border-radius:5px;
    cursor: pointer !important;
    font-family: var(--CircularB);
    margin-top: 8px;
    margin-bottom: 8px;
    outline: 0;
    &.large {
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        padding: 11px 16px 13px 16px;
        @media only screen and (max-width:1023px) {
            width: 100%;
        }
    }
    &.medium {
        font-weight: medium;
        padding: 4px 16px;
        font-size: 18px;
        line-height: 24px;
    }
    &.primary {
        color: var(--perle);
        background-color: var(--page-primary);
        border: 2px solid var(--page-primary);
        transition: all 0s linear;
        &:hover {
            box-shadow: 0px 0px 6px rgba(0,0,0,0.16), 0px 0px 2px rgba(0,0,0,0.32);
            transition: all 0.08s linear;
        }
    }
    &.secondary {
        color: var(--page-primary);
        background-color: transparent;
        border: 2px solid var(--page-primary);
        box-shadow: inset 0px 0px 0px var(--page-primary), inset 0px 0px 0px var(--page-primary);
        transition: all 0.28s ease-in-out;
        &:hover {
            box-shadow: inset 500px 0px 0px var(--page-primary), inset -500px 0px 0px var(--page-primary), 0px 0px 6px rgba(0,0,0,0.16), 0px 0px 2px rgba(0,0,0,0.32);
            transition: all 0.16s ease-in-out;
            color: var(--perle);
        }
    }
}

.button-container {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    column-gap: 16px;
    @media only screen and (max-width:1023px) {
        grid-auto-flow: row;
    }
    & > a > button {
        width:100%;
    }
}

/*Gallery*/
.gallery-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin: 40px auto;
}
.gallery-item {
    max-width:288px;
    margin: 24px 0;
    @media only screen and (max-width:1023px) {
        max-width:100%;
        margin: 24px 0;
    }
    h4::before {
        content: url(/icons/tick-fill.svg);
    }
    h4.not-included::before {
        content: url(/icons/not-included.svg);
    }
    h4.always-included::before {
        content: url(/icons/tick-outline.svg);
    }
    h4::before, h4.not-included::before, h4.always-included::before {
        zoom:80%;
        position: relative;
        top:2px;
    }
    p {
        margin: 0;
        opacity: 0.75;
    }
}

.image-text, .image-text-reverse {
    display:flex;
    flex-flow: row nowrap;
    align-items:flex-start;
    justify-content:space-between;
    margin: 80px auto;
    &:last-child {
        margin-bottom: 0px; /*Preventing unuseful bottom-margin when the image-text component is the last child of its parent, but leaving the margin when there's something else below it*/
    }
    img, picture {
        width: 104px;
        height: 104px;
        margin: 0;
        margin-right: 40px;
        @media only screen and (max-width:1023px) {
            margin:auto;
        }
    }
    @media only screen and (max-width:1023px) {
        flex-flow: column nowrap;
        align-items:center;
        justify-content: center;
        & > * {
            margin: 24px auto;
        }
    }
}

.image-text-reverse {
    img, picture {
        margin-right: 0px;
        margin-left: 40px;
        @media only screen and (max-width:1023px) {
            margin: auto;
            margin-bottom: 16px;
        }
    }
}

/*Image + description or quote component*/
.image-desc {
    display:flex;
    flex-flow: column nowrap;
    justify-content:space-between;
    align-items:flex-start;
    align-self: stretch;
    & > * {
        margin-top: 4px;
        margin-bottom:4px;
    }
    &:first-child {
        margin-top: 0px;
    }
    &:last-child {
        margin-bottom: 0px;
    }
}
.quote img, .quote picture {
    border-radius:100%;
}
.quote .image-desc p:first-of-type {
    font-family: var(--Zilla) !important;
    font-size: 21px;
} 

/*Pricing card for presenting one offer – adaption for several can be found in pricing.css*/
.pricing-card {
    text-align:center;
    width: 400px;
    padding: 32px 16px 8px 16px;
    margin:auto;
    display:flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    border: 2px solid rgba(0,0,0,0.08);
    border-radius:14px;
    box-shadow: 0 0 16px 0 rgba(0,0,0,0.08);
    @media only screen and (max-width:1023px) {
        width:auto;
    }
    button.tertiary {
        margin:0px auto;
    }
    h3 {
        font-size:18px;
        font-weight:400;
        font-family: var(--CircularB);
        text-align:center;
        margin-bottom:8px;
        /*width: calc(100% - 32px);*/
        margin-left: auto;
        margin-right: auto;
        @media only screen and (max-width: 414px) {
            /*width: calc(100% - 32px);*/
        }
        @media only screen and (max-width: 375px) {
            /*width: calc(100% - 16px);*/
        }
    }
}
.discount-container {
    display:flex;
    flex-flow:row nowrap;
    justify-content:center;
    margin:auto;
    margin-top: 16px;
    align-items:flex-end;
}
.discount-path {
    color: var(--page-primary);
    height:12px;
    width:12px;
}
.discount {
    color: var(--page-primary);
    font-size:14px;
    font-family: var(--CircularB);
    font-weight:400;
    text-align:center;
    margin:0 0 0 4px;
}
.pricing-toggle {
    text-align:center;
    margin-bottom:24px;
    button {
        margin: 0;
        border: none;
        border-radius: 0;
        font-family: var(--CircularB);
        font-weight:400;
        font-size:16px;
        width:112px;
        color: var(--encre);
        background-color: transparent;
    }
    button.left {
        margin-right:-3px;
    }
    button.right {
        margin-left:-3px;
    }
    button:focus {
        outline: 0;
    }
    button.pricing-selected {
        font-weight: 600;
        border-bottom: 4px solid var(--page-primary);
    }
}
#volume-choice {
    color: var(--ardoise);
    margin-top:0px;
    margin-bottom:8px;
    margin-top:-16px;
}
#number-of-licenses-toggle {
    margin:auto;
    margin-bottom:16px;
    display:flex;
    flex-flow:row nowrap;
    justify-content: center;
    button {
        margin-top: 0;
        margin-left: 0px;
        margin-right: 0px;
        border-radius:0px;
        border: 1px solid var(--ardoise);
        font-family: var(--CircularB);
        font-weight:400;
        width:auto;
        color: var(--ardoise);
        background-color:transparent;
    }
    button:first-of-type {
        border-radius:100px 0 0 100px;
        margin-right:-1px;
    }
    button:last-of-type {
        border-radius:0 100px 100px 0;
        margin-left:-1px;
    }
}
.volume-selected {
    color: var(--perle) !important;
    font-weight:500 !important;
    background-color: var(--ardoise) !important;
}
.offer {
    display:flex;
    flex-flow:column nowrap;
    align-items: center;
    justify-content:flex-start;
    width: -moz-fit-content;
    width:fit-content;
    margin:auto;
    margin-bottom: 16px;
    padding-top: 8px;
    p {
        margin-top: 4px;
        margin-bottom: 4px;
    }
}

.pricing-value {
    display:flex;
    flex-flow:row nowrap;
    align-items:flex-start;
    justify-content:center;
}
.pricing-value .currency, .price-after-colon{
    font-weight:700;
    font-size: 24px;
    margin:0;
    line-height:1em;
}
.price-before-colon {
    font-weight:700;
    font-size:36px;
    margin:0;
    line-height:0.85em;
}
.price-period {
    font-weight: 400;
    font-size:16px;
    margin:0;
    line-height:1em;
    align-self:flex-end; /*For using with select instead of paragraph, replace by align-self:center in the page-specific css*/
    margin-top:4px;
    select {
        font-family:var(--CircularB);
        border:none;
        outline:none;
        font-size:18px;
    }
}

/*Article gallery styles*/
#more-btn {
    color: var(--ardoise);
    border: none;
    background-color: transparent;
    margin:auto;
    text-decoration: underline !important;
    font-size: 16px;
}
.article-gallery {
    margin:auto;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    .button-container {
        width:100%;
    }

    // [gboya] Hack: override the default color for <a> 
    h1, h2 {
        color: var(--encre);
    }

    article {
        margin-bottom: 24px;
        height: fit-content;
        img {
            width: 100%;
            cursor: pointer;
            overflow: hidden;
        }
        p {
            margin-top: 8px;
            margin-bottom: 8px;
        }
        .category {
            font-family: var(--CircularB);
            font-size: 14px;
            text-transform: uppercase;
            font-weight: 500;
            color: var(--incarnat);
            margin-bottom: 0px;
        }
        .publish-date {
            color: rgba(0,0,0,0.48);
            margin-top: 0px;
            font-size: 14px;
        }
        .summary {
            font-family: var(--Zilla);
            font-weight: 400;
            font-size: 21px;
            font-style: italic;
            width: 100%;
            button {
                font: var(--Zilla) 16px 500;
                display: inline-block;
            } 
        }
        h1, h2 {
            margin-top: 8px;
        }
    }
    .article-image-container {
        width: 100%;
        height: 56.25%;
        display: inline-block;
        overflow: hidden;
        border-radius: 5px;
        box-shadow: 0 4px 8px rgba(0,0,0,0.08);
        position:relative;
        &::after {
            content: "Keep reading";
            border: 2px solid var(--perle);
            border-radius:5px;
            background-color: transparent;
            color: var(--perle);
            font-family: var(--CircularB);
            position: absolute;
            top: calc(50% - 20px);
            left: calc(50% - 61px);
            padding: 8px 8px;
            pointer-events: none;
            opacity: 0;
            transition: opacity .8s ease-in;
        }
        &:hover::after {
            opacity: 1;
        }
        img {
            transition: all 1.48s ease-out;
            min-height:100%;
            min-width: 100%;
            object-fit: cover;
            filter: brightness(100%);
            &:hover {
                transform: scale(1.16);
                filter: brightness(40%);
            }
        }
    }
    .article-primary {
        width: 100%;
        display: flex;
        flex-flow: column nowrap;
        margin-bottom: 56px;
        h1, h2 {
            font-family: var(--Flex);
            font-size:32px;
            font-weight: 600;
            margin-bottom: 16px;
            margin-top: 0px;
        }
    }
    .article-secondary {
        width: calc(50% - 16px);
        display: flex;
        flex-flow: column nowrap;
        margin-bottom: 56px;
        .article-image-container {
            height: 232px;
            @media only screen and (max-width: 728px) {
                height: 200px;
            }
        }
        @media only screen and (max-width: 728px) {
            width: 100%;
        }
        h1, h2 {
            font-family: var(--CircularB);
            font-size:24px;
            font-weight: 600;
            margin-bottom: 16px;
            margin-top: 0px;
        }
    }
    .article-tertiary {
        width: 100% !important;
        display: flex;
        flex-flow: row nowrap;
        .article-image-container {
            width: 240px;
            height: 144px;
            flex: none;
            margin-right: 32px;
            @media only screen and (max-width: 764px) {
                width: calc(33% - 16px);
                img {
                    min-height: auto;
                }
            }
        }
        h1, h2 {
            font-family: var(--Flex);
            font-size:24px;
            font-weight: 500;
            margin-bottom: 16px;
        }
        @media only screen and (max-width: 588px) {
            flex-flow: column nowrap;
            .article-image-container {
                width: 100%;
                max-height: 200px;
                margin-bottom: 24px;
            }
        }
    }
}
button.tertiary {
    border: none;
    padding: 0;
    margin: 0;
    background-color: transparent;
    color: var(--page-primary);
    width: fit-content;
    display: inline-block;
    &:hover {
        text-decoration: underline;
    }
    &::before {
        content: "→ ";
    }
}

/*Logo Carrousel styles*/
.logo-carrousel {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-end;
    overflow: hidden;
    padding: 0 80px;
    position: relative;
    left: -38px;
    @media only screen and (max-width: 768px) {
        left: -38px;
        padding: 0 48px;
    }
    @media only screen and (max-width: 414px) {
        left: 2px;
        padding: 0 16px;
    }
    @media only screen and (max-width: 375px) {
        left: -16px;
        padding: 0 6px;
    }
    @media only screen and (max-width:320px) {
        left: 0px;
        padding: 0;
        margin: auto 58px;
    }
    img {
        width: 144px !important;
        max-width: none !important;
        object-fit: contain;
        height:56px;
        margin:16px;
        position: relative;
        left: 0;
        opacity: 0%;
        -webkit-transition: left 1999ms linear, opacity 1350ms linear;
        -moz-transition: left 1999ms linear, opacity 1350ms linear;
        -o-transition: left 1999ms linear, opacity 1350ms linear;
        transition: left 1999ms linear, opacity 1350ms linear;
        @media only screen and (max-width:320px) {
            width: 72px;
            height: 56px;
            object-fit: contain;
            margin:16px 16px;
        }
    }
}
.notransition {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
    -webkit-transition-property: none !important;
    -moz-transition-property: none !important;
    -o-transition-property: none !important;
    transition-property: none !important;
    -webkit-transform: none !important;
    -moz-transform: none !important;
    -o-transform: none !important;
    transform: none !important;
}
/*End logo carrousel styles*/

/*Styles for quote carousel*/
.quote-carousel {
    display: flex;
    flex-flow: column nowrap;
    margin-bottom: 40px;
    @media only screen and (max-width: 1023px) {
        margin-top: auto;
    }
    .all-carousel-cards {
        opacity: 1;
        transition: opacity 0.48s ease-in-out;
        display:flex;
        flex-flow: row nowrap;
        align-items: flex-start;
        flex:1;
        .carousel-card {
            display: flex;
            flex-flow: column nowrap;
            justify-content: center;
            align-items: center;
            margin-left: auto;
            margin-right: auto;
            width: fit-content;
            @media (max-width: 960px) {
                &:first-child, &:last-child {
                    display: none;
                }
            }
        }
    }
    .quote-card-picture {
        object-fit: contain;
        width:80px;
        height:80px;
        max-width: initial;
        position: relative;
        top: 40px;
        border-radius:100%;
        @media only screen and (max-width: 728px) {
            width:60px;
            height:60px;
            top: 30px;
        }
        @media only screen and (max-width: 414px) {
            width:40px;
            height:40px;
            top: 20px;
        }
    }
    .quote-card-name {
        font-weight: 600;
    }
    .quote-card-content {
        color: var(--page-primary);
        font-weight: 500;
        font-size: 21px;
        font-family: var(--Zilla);
        font-style: italic;
        text-align: center;
        margin-top: 0px;
        &::before, &::after  {
            font-family: var(--Zilla);
            font-size: 24px;
        }
        &::before {
            content: "“";
        }
        &::after {
            content: "”";
        }
    }
}
.quote-carousel-pictures {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
    width:100%;
    margin-right: 0px;
    margin-left: 0px;
    @media (max-width: 960px) {
        .all-carousel-cards .carousel-card {
            &:last-child, &:first-child {
                display: none;
            }
        }
    }
}
.quote-carousel-content {
    /*display: flex;*/
    display:none;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.active-quote {
    display:flex;
}
.product-carousel, .quote-carousel, .pricing-carousel {
    .previous, .next {
        width : 36px;
        height: 36px;
        @media only screen and (max-width: 1023px) {
            width: 24px;
            height: 24px;
        }
        @media (hover: hover) {
            &:hover {
                filter: invert(73%) sepia(39%) saturate(3699%) hue-rotate(322deg) brightness(98%) contrast(95%);
                cursor: pointer;
            }
        }
    }
    .previous {
        margin-right: 40px;
        @media only screen and (max-width: 1023px) {
            margin-right: 0px;
        }
    }
    .next {
        margin-left: 40px;
        @media only screen and (max-width: 1023px) {
            margin-left: 0px;
        }
    }
}
.quote-carousel .quote-card-content {
    color: var(--page-primary);
    font-weight:500;
    font-size:21px;
    font-family: var(--Zilla);
    font-style: italic;
    text-align: center;
    margin-top: 0px;
}
.main-card {
    .quote-card-picture {
        width: 160px;
        height: 160px;
        top: 0px;
        @media only screen and (max-width: 728px) {
            width: 120px;
            height: 120px;
        }
        @media only screen and (max-width: 414px) {
            width: 80px;
            height: 80px;
        }
    }
}

.pricing-carousel {
    display: flex;
    flex-flow: row nowrap;
    align-items:center;
    justify-content: space-around;
    .all-carousel-cards {
        display:flex;
        flex-flow: row nowrap;
        .pricing-card {
            &:not(:first-of-type) {
                @media only screen and (max-width:768px) {
                    display: none;
                }
            }
        }
    }
    .previous, .next {
        @media only screen and (min-width:768px) {
            display: none;
        }
    }
}

/*Email signup form above footer*/
.email-signup {
    background-color: var(--perle);
    padding-top: 24px;
    padding-bottom: 32px;
    h2, h3 {
        font-family: var(--CircularB);
        font-weight: 400;
        margin: 0;
        margin-bottom:16px;
        color: var(--encre);
    }
    h2 {
        font-size: 32px;
        line-height: 42px;
    }
    h3 {
        font-size: 18px;
        line-height:24px;
    }
    p, a {
        color: var(--encre);
        font-size:14px;
    }
    form {
        width: 100%;
        margin: 16px auto;
    }
    input[type=text], input[type=email] {
        border-radius:5px;
        border: none;
        background-color: var(--selene);
        font-size: 21px;
        font-weight: 300;
        color: rgba(0,0,0,0.5);
        padding: 6px 16px;
        width: 100%;
        caret-color: var(--incarnat);
    }
    input {
        &:focus {
            color: var(--encre);
            outline: none;
        }
        &:active {
            color: var(--encre);
            outline: none;
        }
    }
    .input-and-button {
        display:flex;
        width:100%;
        flex-flow: row nowrap;
        justify-content:space-between;
        margin-top:16px;
        margin-bottom: 16px;
        * {
            margin: 0;
        }
        :first-child {
            margin-right: 8px;
        }
        :last-child {
            margin-left: 8px;
            flex-shrink: 0;
        }
        button {
            width:128px;
        }
        label {
            font-size: 14px;
            font-family: var(--CircularB);
            font-weight: 400;
        }
    }
}
.custom-checkbox {
    display:flex;
    flex-flow:row nowrap;
    align-items:flex-start;
    justify-content:stretch;
    p {
        margin:0;
        line-height:18px;
    }
    input[type=checkbox] {
        position:absolute;
        left:-9999px;
    }
    label:before {
        content: url(../icons/checkbox-unchecked.svg);
        margin-right:8px;
        cursor:pointer;
    }
    input[type=checkbox]:checked ~ label:before {
        content: url(../icons/checkbox-checked.svg);
    }
}
#rgpd-error-message {
    display: none;
    width: -moz-fit-content;
    width: fit-content;
    font-size:14px;
    color: var(--vermillon);
    padding-left:4px;
    padding-right:4px;
    margin-right:4px;
}
.error-message {
    display: none;
    width: -moz-fit-content;
    width: fit-content;
    font-size:14px;
    color: var(--vermillon) !important;
    padding-left:4px;
    padding-right:4px;
    margin-right:4px;
}
/*Default styles for the signup form, controlled by js*/
#email-signup-form-step-1-error {
    display: none;
    width: -moz-fit-content;
    width:fit-content;
    font-size:14px;
    color: var(--vermillon);
    padding-left:4px;
    padding-right:4px;
    margin-right:4px;
}
#email-signup-form-step-1-button {
    opacity: 100%;
    transition: opacity 300ms linear;
}
#email-signup-form-step-2 {
    display: none;
    max-height:0px;
    opacity:0%;
    transition: all 1000ms linear;
}
#email-signup-form-step-3 {
    display: none;
    max-height:0px;
    opacity:0%;
    transition: all 1000ms linear 1000ms;
}
#mc_embed_signup {
    display: none !important;
}

/*Utilities*/
.fullwidth {
    min-width: 100%;
}
.text-center {
    text-align: center !important;
    margin-right: auto !important;
    margin-left: auto !important;
}
@media only screen and (max-width: 1023px) {
    .desktop-only {
        display:none !important;
    }
    .mobile-reverse {
        flex-direction: column-reverse;
    }
}
@media only screen and (min-width: 1024px) {
    .mobile-only {
        display:none !important;
    }
}
.hidden {
    display: none !important;
}