/*Specific CSS for this page*/
:root {
    --page-primary: var(--incarnat); /*Change the value of this variable to a different color in the design system to change color of the elements*/
    --page-secondary: var(--marine); /*Change the value of this variable to a different color in the design system to change color of the elements*/
}

.webp {
    #maestria-header-banner, #maestria-main-cta {
        background-image: url(/images/pictures/maestria/maestria-bg@576w.webp);
        @media only screen and (-webkit-min-device-pixel-ratio: 2) {
            background-image: url(/images/pictures/maestria/maestria-bg@1146w.webp);
        }
        @media only screen and (-webkit-min-device-pixel-ratio: 3) {
            background-image: url(/images/pictures/maestria/maestria-bg@1472w.webp);
        }
        @media only screen and (min-width:764px) {
            background-image: url(/images/pictures/maestria/maestria-bg@764w.webp);
        }
        @media only screen and (min-width:764px) and (-webkit-min-device-pixel-ratio: 2) {
            background-image: url(/images/pictures/maestria/maestria-bg@1472w.webp);
        }
        @media only screen and (min-width:764px) and (-webkit-min-device-pixel-ratio: 3) {
            background-image: url(/images/pictures/maestria/maestria-bg@1920w.webp);
        }
        @media only screen and (min-width:1146px) {
            background-image: url(/images/pictures/maestria/maestria-bg@1146w.webp)
        }
        @media only screen and (min-width:1146px) and (-webkit-min-device-pixel-ratio: 2) {
            background-image: url(/images/pictures/maestria/maestria-bg@1920w.webp);
        }
        @media only screen and (min-width:1146px) and (-webkit-min-device-pixel-ratio: 3) {
            background-image: url(/images/pictures/maestria/maestria-bg@2880w.webp);
        }
        @media only screen and (min-width:1320px) {
            background-image: url(/images/pictures/maestria/maestria-bg@1320w.webp);
        }
        @media only screen and (min-width:1320px) and (-webkit-min-device-pixel-ratio: 2) {
            background-image: url(/images/pictures/maestria/maestria-bg@2880w.webp);
        }
        @media only screen and (min-width:1472px) {
            background-image: url(/images/pictures/maestria/maestria-bg@1472w.webp);
        }
        @media only screen and (min-width:1472px) and (-webkit-min-device-pixel-ratio: 2) {
            background-image: url(/images/pictures/maestria/maestria-bg@2880w.webp);
        }
        @media only screen and (min-width:1920px) {
            background-image: url(/images/pictures/maestria/maestria-bg@1920w.webp);
        }
        @media only screen and (min-width:1920px) and (-webkit-min-device-pixel-ratio: 2) {
            background-image: url(/images/pictures/maestria/maestria-bg@2880w.webp);
        }
        @media only screen and (min-width:2880px) {
            background-image: url(/images/pictures/maestria/maestria-bg@2880w.webp);
        }
    }
}
.no-webp {
    #maestria-header-banner,#maestria-main-cta {
        background-image: url(/images/pictures/maestria/maestria-bg@576w.jpg);
        @media only screen and (-webkit-min-device-pixel-ratio: 2) {
            background-image: url(/images/pictures/maestria/maestria-bg@1146w.jpg);
        }
        @media only screen and (-webkit-min-device-pixel-ratio: 3) {
            background-image: url(/images/pictures/maestria/maestria-bg@1472w.jpg);
        }
        @media only screen and (min-width:764px) {
            background-image: url(/images/pictures/maestria/maestria-bg@764w.jpg);
        }
        @media only screen and (min-width:764px) and (-webkit-min-device-pixel-ratio: 2) {
            background-image: url(/images/pictures/maestria/maestria-bg@1472w.jpg);
        }
        @media only screen and (min-width:764px) and (-webkit-min-device-pixel-ratio: 3) {
            background-image: url(/images/pictures/maestria/maestria-bg@1920w.jpg);
        }
        @media only screen and (min-width:1146px) {
            background-image: url(/images/pictures/maestria/maestria-bg@1146w.jpg);
        }
        @media only screen and (min-width:1146px) and (-webkit-min-device-pixel-ratio: 2) {
            background-image: url(/images/pictures/maestria/maestria-bg@1920w.jpg);
        }
        @media only screen and (min-width:1146px) and (-webkit-min-device-pixel-ratio: 3) {
            background-image: url(/images/pictures/maestria/maestria-bg@2880w.jpg);
        }
        @media only screen and (min-width:1320px) {
            background-image: url(/images/pictures/maestria/maestria-bg@1320w.jpg);
        }
        @media only screen and (min-width:1320px) and (-webkit-min-device-pixel-ratio: 2) {
            background-image: url(/images/pictures/maestria/maestria-bg@2880w.jpg);
        }
        @media only screen and (min-width:1472px) {
            background-image: url(/images/pictures/maestria/maestria-bg@1472w.jpg);
        }
        @media only screen and (min-width:1472px) and (-webkit-min-device-pixel-ratio: 2) {
            background-image: url(/images/pictures/maestria/maestria-bg@2880w.jpg);
        }
        @media only screen and (min-width:1920px) {
            background-image: url(/images/pictures/maestria/maestria-bg@1920w.jpg);
        }
        @media only screen and (min-width:1920px) and (-webkit-min-device-pixel-ratio: 2) {
            background-image: url(/images/pictures/maestria/maestria-bg@2880w.jpg);
        }
        @media only screen and (min-width:2880px) {
            background-image: url(/images/pictures/maestria/maestria-bg@2880w.jpg);
        }
    }
}
#maestria-header-banner {
    background-image: url(/images/pictures/maestria/maestria-bg@2880w.webp);
    background-position: 50% 50%;
    background-size: cover;
    margin-top:-40px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: stretch;
    @media only screen and (max-width: 1023px) {
        background-blend-mode: darken;
        background-color: rgba(0,0,0,0.24);
        min-height: calc(100*var(--vh) - 88px);
    }
    .hero {
        display: flex;
        min-height: 64vh;
        align-self: center;
        flex-flow: column nowrap;
        justify-content: center;
        align-items:center;
        margin-top:80px;
        @media only screen and (max-width:1023px) {
            margin-top: 40px;
        }
        h1, h2 {
            font-weight: 500;
            text-shadow: 0px 0px 16px rgba(0,0,0,0.16), 0px 0px 8px rgba(0,0,0,0.24), 0px 0px 4px rgba(0,0,0,0.4);
        }
        h2 {
            font-size: 48px;
            @media only screen and (max-width:1023px) {
                font-size: 24px;
            }
        }
        p {
            text-shadow: 0px 0px 4px rgba(0,0,0,0.48);max-width:640px;
        }
        button.secondary {
            backdrop-filter: blur(6px);
        }
    }
    * {
        text-align: center;
    }
}

#maestria-header-logo {
    display: block;
    margin:auto;
    margin-bottom:40px;
    width:300px;
    max-width:70%;
}

#livescore {
    padding-top:120px;
    button.primary {
        display:block;
        margin:auto
    }
}

#maestria {
    img.title-like-img {
        height: auto;
        display:block;
        width:300px;
        max-width:88%;
        margin:auto;
        margin-bottom: 24px;
    }
    h2 {
        text-align:center;
    }
}

#maestria-main-cta {
    background-position: 50% 50%;
    background-size: cover;
    border-radius:8px;
    margin:auto;
    box-shadow: 0px 4px 8px rgba(0,0,0,.16);
    div {
        padding:24px;
        backdrop-filter: blur(24px) brightness(0.9);
        border-radius: 8px;
        * {
            color: var(--perle);
        }
    }
}